<template>
  <v-main class="custom-main">
    <v-container>
      <v-row
        justify="center"
        class="fill-heigth"
        v-if="false && user.role.includes('owner') || user.role.includes('admin')"
      >
        <v-col cols="12">
          <v-row>
            <v-col cols="4">
              <v-card>
                <v-toolbar color="indigo" dark>
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>

                  <v-toolbar-title>Mi perfil</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Cajas</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Movimientos</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card>
                <v-toolbar color="indigo" dark>
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>

                  <v-toolbar-title>Administrar</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Proveedores</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Marcas</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card>
                <v-toolbar color="indigo" dark>
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>

                  <v-toolbar-title>Productos</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Productos</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Marcas</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                          <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Categorías</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card>
                <v-toolbar color="indigo" dark>
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>

                  <v-toolbar-title>Mis sucursales</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Sucursal 1</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Sucursal 2</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card>
                <v-toolbar color="indigo" dark>
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>

                  <v-toolbar-title>Mi perfil</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Cajas</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Movimientos</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else justify="center" class="fill-heigth">
        <v-col cols="12">
          <router-view></router-view>
          <v-row>
            <v-col cols="12">
              <h2>Accesos directos</h2>
            </v-col>
            <template v-if="!showAccessVenta">
              <v-row>
                <v-col cols="12">
                  <v-alert prominent type="info"
                    >Inicia la caja para realizar una venta.
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="green"
                    block
                    x-large
                    class="parpadea"
                    @click="$router.push('/iniciarCaja')"
                    >INICIAR CAJA</v-btn
                  >
                </v-col>
              </v-row>
            </template>

            <v-col v-for="acceso in accesosDirectos" :key="acceso.id" cols="12" md="6" lg="3">
              <v-card
                v-if="acceso.valid"
                height="100%"
                @click="goTo(acceso.to)"
              >
                <v-card-title class="justify-center">
                  <v-icon large> {{ acceso.icon }}</v-icon>
                </v-card-title>
                <v-card-title class="justify-center">
                  {{ acceso.title }}
                </v-card-title>
                <v-card-text>
                  <div class="text-center">
                    {{ acceso.message }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src

import { mapGetters, mapState } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      url: null,

      navAdministrar: [
        {
          id: 1,
          icon: "mdi-sitemap",
          text: "Categorias",
          route: "categorias",
        },
        {
          id: 2,
          icon: "mdi-account-multiple",
          text: "Proveedores",
          route: "proveedoresHome",
        },
        { id: 3, icon: "mdi-basket", text: "Marcas", route: "marcas" },
      ],
      links: [],
    };
  },
  computed: {
    accesosDirectos() {
      var accesos = [];
      if (this.configuracion.menuVentaCruachan) {
        accesos.push({
          title: "Pedidos",
          message: "F2",
          to: "pedidoVenta",
          icon: "mdi-cash ",
          valid: this.arqueoCaja,
        });
      } else {
        accesos.push({
          title: "Nueva venta",
          message: "F2",
          to: "nuevaVenta",
          icon: "mdi-cash ",

          valid: this.arqueoCaja,
        });
      }
      if (this.configuracion.gastosAccesoDirecto) {
        accesos.push({
          title: "Gastos",
          message: "",
          to: "egresosGastos",
          icon: "mdi-currency-usd ",

          valid: true,
        });
      }
      return accesos;
    },
    ...mapState("sucursales", ["configuracion", "arqueoCaja"]),

    ...mapState("auth", ["group", "user"]),

    showAccessVenta() {
      return Boolean(this.arqueoCaja);
    },
  },
  methods: {
    goTo(to) {
      console.log(to);
      this.$router.push({ name: to });
    },
    post(data) {
      this.axios.post(this.url, data).then((response) => {
        alert(response);
      });
    },
    get() {
      this.axios.get(this.url).then((response) => {
        alert(response);
      });
    },
  },

  created() {
    this.links.push({
      id: 1,
      icon: "mdi-home",
      text: "Inicio",
      route: "home",
    });
    if (this.arqueoCaja) {
      this.links.push({
        id: 12,
        icon: "mdi-cart-arrow-right",
        text: "Ventas",
        route: "ventas",
      });
    }
    if (this.configuracion.compra) {
      this.links.push({
        id: 11,
        icon: "mdi-store-outline",
        text: "Compras",
        route: "compras",
      });
    }
    if (this.configuracion.promociones) {
      this.links.push({
        id: 4,
        icon: "mdi-sale",
        text: "Promociones",
        route: "promociones",
      });
    }

    this.links.push({
      id: 4,
      icon: "mdi-format-list-checks",
      text: "Productos",
      route: "productosListado",
    });
    if (this.configuracion.clientes) {
      this.links.push({
        id: 4,
        icon: "mdi-account-group",
        text: "Clientes",
        route: "clientes",
      });
    }
    if (this.arqueoCaja) {
      this.links.push(
        {
          id: 5,
          icon: "mdi-book-edit-outline",
          text: "Egresos",
          route: "egresos",
        },

        {
          id: 15,
          icon: "mdi-bank",
          text: "Movimientos de caja",
          route: "movimientos",
        }
      );
    } else {
      this.links.push({
        id: 15,
        icon: "mdi-bank",
        text: "Iniciar caja",
        route: "iniciarCaja",
      });
    }
    if (this.group.includes("esDueño") || this.group.includes("esAdmin")) {
      this.links.push(
        {
          id: 14,
          icon: "mdi-chart-bar",
          text: "Reportes",
          color: "green",
          route: "reporteInformeMensual",
        },
        {
          id: 16,
          icon: "mdi-clipboard-account",
          text: "Empleados",
          color: "blue",
          route: "empleados",
        },
        {
          id: 17,
          icon: "mdi-cog",
          text: "Configuración",
          color: "grey",
          route: "configuracion",
        }
      );
    }
    var array = this.links.concat(this.navAdministrar);
    this.$store.commit("SET_LINKS", array);
    this.$store.commit("SET_LAYOUT", { layout: "layout-home", withNav: true });
  },
};
</script>
<style scoped>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>
